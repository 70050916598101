<template>
    <div class="text-gray-400">
        <ul v-if="pages.last_page > 1" class="inline-flex rounded-sm border border-gray-700 divide-x divide-gray-700 bg-gray-800">
        <li class="h-9 hover:bg-gray-700"><button @click.prevent="$emit('get-page', pages.current_page-1)" :class="[pages.current_page < 2? 'disabled text-gray-300':'']" class="flex items-center h-full px-3.5" :disabled="pages.current_page < 2"><i class="icon-arrow-left12"></i></button></li>
        <li v-for="page in rangeOne" :key="page.page" class="h-9" :class="[pages.current_page == page.page ? 'bg-green-600 text-white':'hover:bg-gray-100']"><a @click.prevent="$emit('get-page', page.page)" href="#" class="flex items-center h-full px-3.5">{{page.page}}</a></li>
        <li v-if="rangeOne.length == 2 && pages.last_page > 10" class="h-9 disabled"><a href="#" class="flex items-center h-full px-3.5">...</a></li>
        <li v-for="page in rangeTwo" :key="page.pgae" class="h-9" :class="[pages.current_page == page.page ? 'bg-green-600 text-white':'hover:bg-gray-100']"><a @click.prevent="$emit('get-page', page.page)" href="#" class="flex items-center h-full px-3.5">{{page.page}}</a></li>    
        <li v-if="rangeThree.length == 2" class="h-9 disabled"><a href="#" class="flex items-center h-full px-3.5">...</a></li>
        <li v-for="page in rangeThree" :key="page.page"  class="h-9" :class="[pages.current_page == page.page ? 'bg-green-600 text-white':'hover:bg-gray-100']"><a @click.prevent="$emit('get-page', page.page)" href="#" class="flex items-center h-full px-3.5">{{page.page}}</a></li>   
        <li class="h-9 hover:bg-gray-100"><button @click.prevent="$emit('get-page', parseInt(pages.current_page)+1)" href="#" :class="[pages.current_page == pages.last_page ? 'disabled text-gray-300':'']" class="flex items-center h-full px-3.5" :disabled="pages.current_page == pages.last_page"><i class="icon-arrow-right13"></i></button></li>
    </ul>
    </div>
</template>
<script>
export default {
    props: ['pages'],
    data () {
        return {
            rangeOne: [],
            rangeTwo: [],
            rangeThree: []
        }
    },
    computed: {
        current_page () {
            return this.pages.current_page
        },
        total_page () {
            return this.pages.last_page
        }
    },
    methods: {
        getData (i) {
            this.$emit('get-page', i)
        },
        setPage (val) {
            this.rangeOne = []
            this.rangeTwo = []
            this.rangeThree = []
            if(this.pages.last_page  <= 10 ) {
                for (let i = 0; i < this.pages.last_page ; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
            }else if(this.pages.last_page  > 10 && val < 7){
                for (let i = 0; i < 8; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
                for (let i = 0; i < 2; i++) {
                    this.rangeThree.push({page: this.pages.last_page  + i -1})
                }
            }else if(val <= this.pages.last_page  - 6 && val > 6){
                for (let i = 0; i < 2; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
                for (let i = 0; i < 7; i++) {
                    this.rangeTwo.push({page: parseInt(val)+i -3})                    
                }
                for (let i = 0; i < 2; i++) {
                    this.rangeThree.push({page: this.pages.last_page  + i -1})
                }
            }else{
                for (let i = 0; i < 2; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
                for (let i = 0; i < 9; i++) {
                    this.rangeThree.push({page: this.pages.last_page  +i -8})
                }
            }
        }
    },
    watch: {
        current_page (val) {
            this.setPage(val)
        },
        total_page () {
            this.setPage(this.current_page)
        }
    }

}
</script>